/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import cx from 'clsx';
import uniqBy from 'lodash/uniqBy';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import useTranslation from 'next-translate/useTranslation';
import sidekick from '@last-rev/contentful-sidekick-util';
import ModuleHero, { ModuleHeroPropTypes } from '../ModuleHero';
import VideoCard, { VideoCardPropTypes } from '../ModuleCard/VideoCard';
import ContentModule from '../ContentModule';
import ModulePagination from '../ModulePagination';
import styles from './PageVideoLanding.module.scss';

export const FilterSelectPropTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string)
};

export const PageVideoLandingPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  videosPerPage: PropTypes.number,
  introHero: PropTypes.shape(ModuleHeroPropTypes),
  videos: PropTypes.arrayOf(PropTypes.shape(VideoCardPropTypes)),
  contentSections: PropTypes.array,
  wistiaVideos: PropTypes.shape({ videos: PropTypes.array }),
  page: PropTypes.number,
  currentVideos: PropTypes.arrayOf(PropTypes.shape(VideoCardPropTypes))
};

export const FilterHeadingPropTypes = {
  option: PropTypes.string.isRequired,
  handleFilterHeadingClick: PropTypes.func.isRequired
};

const FilterHeading = ({ option, handleFilterHeadingClick }) => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const mobileCheck = document.querySelector('.mobile-check');
    setIsMobile(mobileCheck.getClientRects().length !== 0);
  }, []);

  return (
    <a
      data-testid={`PageVideoLanding-filterHeading-${option}`}
      className={cx(styles.filterHeading, 'filterHeading', open ? styles.open : undefined)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen((prev) => !prev && !isMobile);
        if (isMobile) handleFilterHeadingClick(option, e);
      }}
      data-uxa-log={`video_filter_heading_${option.toLowerCase().replace(/\s+/g, '_')}`}
      data-uxa-interactions="click shown"
      href="#">
      {option}
    </a>
  );
};

const PageVideoLanding = ({
  _id,
  _contentTypeId,
  slug,
  introHero,
  videos,
  videosPerPage,
  contentSections,
  wistiaVideos,
  page,
  currentVideos
}) => {
  const dedupedVideos = uniqBy(videos, (video) => video._id).sort(
    (a, b) => new Date(b._createdDate) - new Date(a._createdDate)
  );

  const [currentPage, setCurrentPage] = useState(page);
  const [videoPages, setVideoPages] = useState(dedupedVideos?.slice(0, videosPerPage));
  const [filteredVideos, setFilteredVideos] = useState(currentVideos);
  const [shownVideos, setShownVideos] = useState(dedupedVideos);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isMobile, setIsMobile] = React.useState(false);
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tag = urlParams.get('subcategory');
    const camelCasedTags = videoPages?.map((video) => video?.tags?.map((el) => camelCase(el)));
    const subjectFilter = videoPages?.map((video) => video?.subjects);

    switch (true) {
      case tag && camelCasedTags?.flat().includes(tag):
        setFilteredVideos(videoPages.filter((_, index) => camelCasedTags[index]?.includes(tag)));
        break;
      case camelCasedTags?.flat().includes(camelCase(activeCategory)):
        setFilteredVideos(videoPages.filter((_, index) => camelCasedTags[index]?.includes(camelCase(activeCategory))));
        break;
      case activeCategory && subjectFilter?.flat().includes(activeCategory):
        setFilteredVideos(videoPages.filter((_, index) => subjectFilter[index]?.includes(activeCategory)));
        break;
      default:
        setFilteredVideos(currentVideos);
        break;
    }
  }, [activeCategory, videoPages, currentVideos]);

  useEffect(() => {
    const mobileCheck = document.querySelector('.mobile-check');
    setIsMobile(mobileCheck.getClientRects().length !== 0);
  }, []);

  const pageCount = Math.ceil(dedupedVideos?.length / videosPerPage);

  const removeClass = (elementClass) => {
    const classNames = {
      checkbox: styles.active,
      filterHeading: styles.open
    };
    Array.from(document.querySelectorAll(`.${elementClass}`)).forEach((elem) => {
      elem?.classList?.remove(classNames[elementClass] || '');
    });
  };

  const sortByValue = (a, b) => a.value.localeCompare(b.value);

  // NOTE: Categories and Subcategories are labels only, not prop names, which are subjects and tags respectively
  // ie. In Contentful, Tags were changed to Subcategories and Subjects changed to Categories
  const getFilters = () => {
    const categories = [];
    const subcategories = [];
    videos?.forEach((video) => {
      video?.tags?.forEach((category) => {
        const subcategoryLabel = upperFirst(camelCase(category));
        if (!subcategories.some((c) => c.value === category)) {
          subcategories.push({
            value: category,
            label: subcategoryLabel ? t(`common:pageVideoSubcategories${subcategoryLabel}`) : category
          });
        }
      });
      const categoryLabel = upperFirst(camelCase(video.subjects));
      if (!categories.some((c) => c.value === video.subjects)) {
        categories.push({
          value: video.subjects,
          label: categoryLabel ? t(`common:pageVideoCategories${categoryLabel}`) : video.subjects
        });
      }
    });

    return [
      {
        category: 'categories',
        label: t('common:labelCategories'),
        options: categories.sort(sortByValue)
      },
      {
        category: 'subcategories',
        label: t('common:labelSubcategories'),
        options: subcategories.sort(sortByValue)
      }
    ];
  };

  const filters = getFilters();

  const handleFilterHeadingClick = (option, e) => {
    const isOpen = e.target.classList.contains(styles.open);
    if (isMobile) removeClass('filterHeading');
    if (e.target.innerText === option && !isOpen) {
      e.target.classList.add(styles.open);
    }
  };

  const showSelectedCards = (option, category) => {
    const selectedOption = camelCase(option);
    const selectedFilter = category === 'categories' ? `category-${selectedOption}` : `subcategory-${selectedOption}`;
    const videoCards = document.querySelectorAll('.videoCard');
    const visibleCards = [];
    Array.from(videoCards).forEach((card) => {
      const videoCard = card;
      videoCard.style.display = 'block';
      if (!card.classList.contains(selectedFilter)) {
        videoCard.style.display = 'none';
      } else {
        visibleCards.push(card);
      }
    });
    setShownVideos(visibleCards);
  };

  const handleViewAll = (e) => {
    e.preventDefault();
    const startIndex = 0;
    const endIndex = videosPerPage;
    setVideoPages(videos.slice(startIndex, endIndex));
    setActiveCategory(null);
    setFilteredVideos(videos);
    setShownVideos(videos);
    window.history.pushState({}, document.title, window.location.pathname);
  };

  const handleCardSubCategoryFilter = (category, e) => {
    e.preventDefault();
    e.stopPropagation();
    showSelectedCards(category, 'subcategories');

    const url = new URL(window.location.href);
    url.searchParams.set('subcategory', camelCase(category));
    window.history.pushState({}, '', url.toString());
  };

  const handleListFilter = (option, category, e) => {
    e.preventDefault();
    setActiveCategory(option);
    const filtered = dedupedVideos.filter((video) =>
      category === 'categories' ? video.subjects === option : video.tags?.includes(option)
    );
    setFilteredVideos(filtered);
    setShownVideos(filtered);
    setVideoPages(filtered.slice(0, videosPerPage));
    setCurrentPage(1);
    window.history.pushState({}, document.title, window.location.pathname);
  };

  const handleClearFilter = (e) => {
    removeClass('checkbox');
    removeClass('filterHeading');
    setFilteredVideos(dedupedVideos);
    handleViewAll(e);
  };

  const handlePageChange = (pageNumber) => {
    const pageIndex = pageNumber - 1;
    const firstIndex = pageIndex * videosPerPage;
    const lastIndex = pageIndex * videosPerPage + videosPerPage;

    const paginatedVideos = [];
    for (let i = 0; i < shownVideos.length; i += videosPerPage) {
      paginatedVideos.push(shownVideos.slice(i, i + videosPerPage));
    }

    if (activeCategory && shownVideos.length > videosPerPage) {
      setVideoPages(paginatedVideos[pageIndex]);
    } else {
      setVideoPages(dedupedVideos.slice(firstIndex, lastIndex));
    }
    setCurrentPage(pageNumber);
  };

  const handleClickDocument = (activeFilter, e) => {
    if (activeFilter && !(activeFilter?.contains(e.target) || activeFilter?.nextElementSibling.contains(e.target))) {
      if (e.target.classList.contains('filterHeading')) {
        e.target.classList.add(styles.open);
      }
      if (isMobile) removeClass('filterHeading');
    }
  };

  React.useEffect(() => {
    const { page: newPage } = router.query;
    if (newPage) {
      const pageNumber = Number(newPage);
      if (!Number.isNaN(pageNumber)) {
        handlePageChange(pageNumber);
      }
    }
  }, [router.query]);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      handleClickDocument(document.querySelector(`.${styles.open}`), e);
    });
    return () => {
      document.removeEventListener('click', handleClickDocument);
    };
  });

  const getWistiaData = (id) => {
    if (!wistiaVideos?.videos?.length) {
      return null;
    }

    const wistiaData = wistiaVideos.videos.find((video) => video.hashed_id === id);
    if (wistiaData) {
      return {
        name: wistiaData.name,
        description: wistiaData.description,
        thumbnail: wistiaData.thumbnail
      };
    }
    return null;
  };

  return (
    <div
      key="PageVideoLanding"
      data-testid="PageVideoLanding"
      className={styles.page}
      {...sidekick(_id, null, _contentTypeId, 'Page Video Landing')}>
      {introHero
        ? (() => (
            <ModuleHero
              _id={introHero._id}
              _contentTypeId={introHero._contentTypeId}
              headerText={introHero.headerText}
              summaryText={introHero.summaryText}
              image={introHero.image}
              className={`theme-${slug}`}
            />
          ))()
        : null}

      <div className="container py-4 mt-2">
        <div className="row">
          <div className="col-24 col-md-5 mb-4">
            <div className={styles.filtersContainer}>
              <a
                href="#"
                className={cx(styles.filter, styles.underline, 'd-none d-md-block mb-3')}
                data-uxa-log="video_filter_view_all"
                data-uxa-interactions="click shown"
                onClick={(e) => handleViewAll(e)}>
                {t('common:textViewAll')}
              </a>
              <p className={cx(styles.filterLabel, 'mb-3')}>{t('common:textFilterBy')}:</p>
              <div className={cx(styles.filtersWrap, 'filtersWrap')} data-testid="PageVideoLanding-filters">
                {filters.map((filter) => (
                  <div className={styles.filterWrap} key={filter.category}>
                    <FilterHeading option={filter.label} handleFilterHeadingClick={handleFilterHeadingClick} />
                    <ul className={styles.filterList}>
                      {filter.options.map((option) => (
                        <li key={camelCase(option.value)}>
                          <a
                            href="#"
                            className={cx(
                              styles.mobile,
                              'checkbox',
                              activeCategory === option.value ? styles.active : ''
                            )}
                            data-uxa-log={`video_mobile_filter_${option.label.toLowerCase().replace(/\s+/g, '_')}`}
                            data-uxa-interactions="click shown"
                            onClick={(e) => handleListFilter(option.value, filter.category, e)}>
                            {option.label}
                          </a>
                          <a
                            href="#"
                            className={cx(styles.desktop, activeCategory === option.value ? styles.active : '')}
                            data-uxa-log={`video_filter_${option.label.toLowerCase().replace(/\s+/g, '_')}`}
                            data-uxa-interactions="click shown"
                            onClick={(e) => handleListFilter(option.value, filter.category, e)}>
                            {option.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <p className={cx(styles.filterLabel, 'mt-3')}>
                <a href="#" className={styles.clear} onClick={(e) => handleClearFilter(e)} data-uxa-log="video_clear_filter" data-uxa-interactions="click shown">
                  {t('common:textClear')}
                </a>
              </p>
            </div>
          </div>
          <div className="col-24 col-md-19">
            <div className={cx(styles.cardWrap, 'cardWrapper')} data-testid="PageVideoLanding-videos">
              {videos
                ? filteredVideos.map((video) => (
                    <VideoCard
                      key={video._id}
                      _id={video._id}
                      headerText={video.videoTitle}
                      summaryText={video.videoSummary}
                      image={video.videoCardImage}
                      tags={video.tags}
                      subjects={video.subjects}
                      slug={`/${slug}/${video.slug}`}
                      tagFilter={handleCardSubCategoryFilter}
                      className={styles.videoCard}
                      wistiaData={getWistiaData(video.video.videoId)}
                    />
                  ))
                : null}
            </div>

            {pageCount > 1 && shownVideos?.length > videosPerPage && (
              <div className="my-5 d-flex justify-content-center">
                <ModulePagination
                  totalPages={
                    shownVideos.length === videos.length ? pageCount : Math.ceil(shownVideos.length / videosPerPage)
                  }
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className={cx(styles.helpSection, 'col-24 mt-5')}>
            {contentSections?.length ? (
              <div
                key="PageVideoLanding-contentSections"
                data-testid="PageVideoLanding-contentSections"
                {...sidekick(_id, 'contentSections', _contentTypeId, 'Content Sections')}>
                {contentSections.map((module) => (
                  <ContentModule wrapSection contentTypeId={module._contentTypeId} fields={module} key={module._id} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={cx(styles.mobile, 'mobile-check')} />
    </div>
  );
};

FilterHeading.propTypes = FilterHeadingPropTypes;

PageVideoLanding.propTypes = PageVideoLandingPropTypes;
PageVideoLanding.defaultProps = {
  introHero: null,
  videos: null,
  videosPerPage: 12,
  contentSections: [],
  wistiaVideos: { videos: [] },
  currentVideos: [],
  page: 1
};

export default PageVideoLanding;
